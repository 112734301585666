@font-face {
  font-family: 'SF Pro Text';
  src: url('../../assets/fonts/SF-Pro-Text/SFProText-Light.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../assets/fonts/SF-Pro-Text/sf-pro-text-regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../assets/fonts/SF-Pro-Text/sf-pro-text-medium.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../assets/fonts/SF-Pro-Text/sf-pro-text-semibold.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../assets/fonts/SF-Pro-Text/sf-pro-text-bold.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'SF Mono Bold, sans-serif';
  src: url('../../assets/fonts/SF-Pro-Text/sf-mono-bold.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('../../assets/fonts/SF-Pro-Display/SFProDisplay-Bold.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}
