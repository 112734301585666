.app_astro_theme {
  background: var(--main-gradient);

  --dark-purple: #573d85;
  --strong-blue: linear-gradient(180deg, #FDDAA6 0%, #DEB475 100%);
  --strong-blue-text: #F1A564;
  --white: #0F1045;
  --pale-blue: #5E4A82;
  --pale-blue-transparent: #5E4A82;
  --pale-blue-input: #F1F3FF;
  --midnight-black-input: #0F1045;
  --main-gradient: linear-gradient(135deg, #351180 0%, #270D33 50%, #0F1045 100%);
  --light-silver: linear-gradient(180deg, rgba(253, 218, 166, 0.5) 0%, rgba(222, 180, 117, 0.5) 100%);
  --light-silver-to-white: #F1F3FF;
  --light-silver-to-lilac-blue: #A6B0D2;
  --midnight-black: #FFFFFF;
  --greyish-blue: #C5B2BF;
  --footer-small-text: #C5B2BF;
  --progress-line: #F1A564;
  --slate-blue: #F1A564;
  --slate-blue-placeholder: #C5B2BF;
  --strong-blue-80: rgba(241, 165, 100, 0.8);
  --button-color: #0F1045;
  --button-active: #0F1045;
  --button-background: var(--strong-blue);
  --button-active-bg: linear-gradient(180deg, #FFA30C 0%, #BC7603 100%);
  --footer-shield: #C5B2BF;
  --light-cornflower-blue: #C5B2BF;
  --gentle-blue: var(--main-gradient);
  --gentle-blue-svg: #C5B2BF;
  --blue-color-text: #FFFFFF;
  --black-color-text: var(--white);
  --transparent-to-gold: var(--strong-blue);
  --transparent-to-white: #FFFFFF;
  --transparent-to-periwinkle: #F1F3FF;
  --white-to-transparent: transparent;
  --soft-blue: #FFFFFF;
  --soft-blue-gray: #C5B2BF;
  --soft-blue-periwinkle: #F1F3FF;
  --pale-lavender-20: rgba(241, 165, 100, 0.2);
  --dark-blue: #FFFFFF;
  --loader-background: rgba(166, 176, 210, 0.6);
}