.progressBarWrapper {
  gap: 12px;
  width: 100%;
  display: flex;
  margin-top: 12px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px 5px 24px;

  &.hidden {
    display: none;
  }
}

.currentRoute {
  color: var(--strong-blue-80);
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.progressBar {
  width: 100%;
  background: var(--pale-lavender);
  height: 4px;
  position: relative;
  margin-bottom: 2px;

  &::before,
  &::after {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  &::before {
    background: var(--progress-line);
  }

  &::after {
    background: var(--pale-lavender);
    right: 0;
  }
}

.progressBarFinish {
  &::after {
    background: var(--progress-line);
    right: 0;
  }
}

.progress {
  top: 50%;
  height: 6px;
  position: absolute;
  border-radius: 8px;
  transition: all .5s ease;
  transform: translateY(-50%);
  background: linear-gradient(90.25deg, var(--progress-line) 0%, #4455f2 473.72%);
}
