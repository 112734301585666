.header {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  background: var(--pale-blue);
  border-bottom: 1px solid var(--transparent-to-periwinkle);
  min-height: 50px;
  position: relative;

  svg {
    max-height: 51px;
  }
}

.avatar {
  height: 27px;
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(134, 152, 204, 0.7);
  border-radius: 50%;
  color: var(--white);
}
