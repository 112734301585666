
* {
  font-family: 'SF Pro Text';
  box-sizing: border-box;
}

h2 {
  font-weight: 600;
  font-size: 24px;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

h4 {
  font-size: 18px;
  font-weight: 400;
}

button {
  font-size: 18px;
  line-height: 20px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  overflow: auto;
}

html,
body {
  height: calc(100vh - calc(100vh - 100%));
  /*height: 100vh;*/
  line-height: 1;
}

.height-initial {
  height: initial;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

svg .weightGraphicPath {
  stroke-dasharray: 500;
  stroke-dashoffset: 0;
  animation: weightGraphicDashAnimation 2s both;
}

@keyframes weightGraphicDashAnimation {
  from {
    stroke-dashoffset: 500;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* @description: Hack for Paywall - for "OR" text */
div[class^='divider'] {
  font-family: 'SF Pro Text Regular', sans-serif;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #cde8f9 var(--white);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 13px;
}

*::-webkit-scrollbar-track {
  margin: 3px;
}

*::-webkit-scrollbar-thumb {
  background-color: #cde8f9;
  border-radius: 10px;
  border: 3px solid var(--white);
  height: 20px;
}

#root {
  height: 100%;
}

input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  cursor: pointer;
}
