:root {
  --font-family-main: 'SF Pro Text', sans-serif;

  --stone-grey: #95959d;
  --button-color: #121620;
  --svg-blue: var(--strong-blue);
  --pale-lavender: #dee5f9;
  --pale-lavender-20: #dee5f9;
  --orange: #ff9649;
  --coral: #ff5c5d;
  --rich-blue: #2b7cf6;
  --bright-white: #FBFBFB;
  --bright-red: #ff5758;
  --light-gray: #D9D9D9;
  --vivid-yellow: #FFC700;
  --pale-gray: #c2cad8;
  --pale-green: #75db9c;
  --pale-cerulean: #82b7ef;
  --greyish: #afafaf;
  --vivid-green: #00ff38;
  --dark-charcoal: #191F2D;
  --blueish-gray: #6b76aa;
  --violet: #9949ff;
  --light-lavender: #c5c5d1;
  --pale-pink: #fcd3df;
  --cream-yellow: #fffbcd;
  --pale-aqua: #c9fae6;
  --pale-seafoam: #d3f1e1;
  --pale-lilac: #dec6fe;
  --pale-peach: #fdddc8;
  --deep-charcoal: #1e1e1e;
  --black: #000000;
  --bright-sea-green: #04a777;
  --deep-cornflower-blue: #4663B7;
  --charcoal-grey: #505051;
  --pale-light-cerulean: #ACD1FF;
}
