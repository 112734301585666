:root {
  // normal theme colors
  --main-gradient: #ffffff;
  --strong-blue: #066fde;
  --strong-blue-text: #066fde;
  --strong-blue-80: rgba(6, 111, 222, 0.8);
  --midnight-black: #121620;
  --footer-small-text: #121620;
  --button-active: #ffffff;
  --button-background: var(--pale-blue);
  --button-active-bg: var(--strong-blue);
  --slate-blue: #6B7BAA;
  --slate-blue-placeholder: #6B7BAA;
  --pale-blue: #eff2fd;
  --pale-blue-input: #eff2fd;
  --midnight-black-input: #121620;
  --greyish-blue: #8e8e93;
  --soft-blue: #4a567a;
  --soft-blue-gray: #4a567a;
  --soft-blue-periwinkle: #4a567a;
  --gentle-blue: #9babd9;
  --gentle-blue-svg: #9babd9;
  --light-silver: #c7c7c7;
  --light-silver-to-white: #c7c7c7;
  --light-silver-to-lilac-blue: #c7c7c7;
  --light-cornflower-blue: #c2ceee;
  --white: #ffffff;
  --dark-blue: #202b47;
  --progress-line: #00a3ff;
  --footer-shield: #B5C4FF;
  --blue-color-text: #0066fd;
  --black-color-text: #0066fd;
  --transparent-to-gold: transparent;
  --transparent-to-white: transparent;
  --transparent-to-periwinkle: transparent;
  --white-to-transparent: #FFFFFF;
  --loader-background: rgba(16, 32, 77, 0.35);
}
